body {
	background-color: #000;
	color: #fff;
	font: Cutive Mono, sans-serif;
	
}

header {
	background-color:black;
  position: sticky;
  top: 0;
   padding-bottom:36px;
}


.dropbtn {
  background-color: #0c0c0c;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  font: 1rem Inconsolata,monospace;
}

.connectButton {
  background-color: #0c0c0c;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  font: 1rem Inconsolata,monospace;
}


.dropdown {
  position: relative;
  display: inline-block;
  font: 1rem Inconsolata,monospace;
  
}

.dropdown-content {
  display: none;
  position: absolute;
  text-align:left;
  left: 0;
  background-color: #0c0c0c;
  min-width: 200px;
  color:white;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  font: 1rem Inconsolata,monospace;
}



.dropdown-content a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font: 1rem Inconsolata,monospace;
}

.dropdown-content a:hover {background-color: #010101;}
.dropdown:hover .dropdown-content {display: block;}
.dropdown:hover .dropbtn {background-color: #090909;}







svg {
	
	width:100%;
	height:100%;
	
}

#tokenIdSelectDiv {
overflow:hidden;
}

#prev-row {
  white-space: nowrap;
  width: 100%;
  display: table;
}

.prev-img {
  vertical-align: middle;
  padding: 3px;
  display: table-cell;
}

.prev-img img {
  max-width: 100%;
}
	  
	  
	  
#previewImage {
	margin-right: 8px!important;
}

.mintFloat {
	float: right;
}	

.perWallet {
float: right;
font-size: 0.6rem
}

.left {
float: left;
font-size: 0.6rem
}


	
.swal-modal {
    background-color: #040404!important;

}

.swal-icon {
visibility:hidden!important;
height:0px!important;
width:0px!important;
}


.swal-title {
    color: #fff!important;
}

.swal-text {
    color: #fff!important;

}

.swal-button {
    background-color: #efefef!important;
	color: #555!important;
	border-radius: 0px!important;
}





a {
	
	
	text-decoration: none!important; 
	color:white;
	
}




h4 {
	
font: 1rem Cutive Mono, sans-serif;

}



img {
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
}


@media only screen and (max-width: 768px) {
	.footer {
		position: fixed;
		left: 0;
		bottom: 0px;
		width: 100%;

		color: #fff;
		font: 0.5rem Inconsolata,monospace;
		border-bottom: 20px solid black;
	}
	
	h1 {
	
	font: 3rem Cutive Mono, sans-serif;
    font-weight: 900;
}

	
}


@media only screen and (min-width: 768px) {
	
		h1 {
	
	font: 4rem Cutive Mono, sans-serif;
    font-weight: 900;
}

	.footer {
	   position: fixed;
	   left: 0;
	   bottom: 0;
	   width: 100%;

	   text-align: center;
	   color: white;
	   font: 0.7rem Cutive Mono, sans-serif;
	   border-bottom: 10px solid black;
	}
}




.commandprompt, .commandprompt:focus {
	
	border:none;
    background-color: #111;
    width: 150px;
    height: 10%;
    color: white;
    font: 1rem Cutive Mono, sans-serif;

	
}


.tooltip {
  position: relative;
  display: inline-block;
  color: white;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
color: white;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}


@media only screen and (min-width: 768px) {
.main-app {
    text-align: center;
    margin: 50px;
}

}


@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .main-app {
    text-align: center;
	margin: 25px;
	
  }
}





@media only screen and (min-width: 768px) {

.desc {
	
	width:62%;
}

}



.cta-button {
	 box-sizing: border-box; 
    padding-top: 15px;
	 padding-bottom: 15px;
	  padding-left: 0px;
	   padding-right: 0px;
    border: none;
    width: 100%;
    color: white;
	background:#090909;
    font-size: 18px;
    cursor: pointer;
	font: 14px Cutive Mono, sans-serif;
}

.cta-button3 {
    padding: 5px;
    border: none;
    min-width: 50px;
    color: white;
    font-size: 12px;
    cursor: pointer;
	background-color: #0c0c0c;
	font: 18px Cutive Mono, sans-serif;
}



select {
	border-radius: 0px;
    border: none;
    color: black;
    font-size: 18px;
}


.connect-wallet-button {
    background: #141414;
	font: 18px Cutive Mono, sans-serif;
	
}

.mint-nft-button {
	padding: 15px;
    background: #141414;
	font: 18px Cutive Mono, sans-serif;
	
}

.attribute-table {
	
	width: 100%;
border: 1px white solid;
}




@media only screen and (min-width: 768px) {

.cta-button4 {
    padding: 15px;
    border: none;
    min-width: 50px;
    color: white;
	width:48%;
    font-size: 12px;
    cursor: pointer;
	background-color: #0c0c0c;
	font: 18px Cutive Mono, sans-serif;
}




.enterbutton {
	

	background-color: #222222;
	text-align: center;
	color: white;
	font: 1rem Cutive Mono, sans-serif;
	border:none;
}

.enterbutton-footer {
	

	background-color: #222222;
	text-align: center;
	color: white;
	font: 1rem Cutive Mono, sans-serif;
	border:none;
}

.consoleTitle, .consoleLinks {
	
	text-align: left;
    color: white!important;
    font: 3rem Cutive Mono, sans-serif;
    margin-top: 10px;
    margin-bottom: 10px;
	
}


.consoleSubtitle {
	
	text-align: left;
    color: white!important;
    font: 2rem Cutive Mono, sans-serif;
    margin-top: 10px;
    margin-bottom: 10px;
	
}

.console-footer {
	
	text-align: left;
    color: white!important;
    font: 1.2rem Cutive Mono, sans-serif;
    margin-top: 10px;
    margin-bottom: 10px;
	
}



}


@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  
  .cta-button4 {
    padding: 15px;
    border: none;
    min-width: 50px;
    color: white;
	width:45%;
    font-size: 12px;
    cursor: pointer;
	background-color: #0c0c0c;
	font: 18px Cutive Mono, sans-serif;
}

.enterbutton {
	

	background-color: #222222;
	text-align: center;
	color: white;
	font: 0.75rem Cutive Mono, sans-serif;
	border:none;
}

.enterbutton-footer {
	

	background-color: #222222;
	text-align: center;
	color: white;
	font: 0.6rem Cutive Mono, sans-serif;
	border:none;
}

.consoleTitle, .consoleLinks {
	
	text-align: left;
    color: white!important;
    font: 3rem Cutive Mono, sans-serif;
    margin-top: 10px;
    margin-bottom: 10px;
	
}


.consoleSubtitle {
	
	text-align: left;
    color: white!important;
    font: 1.2rem Cutive Mono, sans-serif;
    margin-top: 10px;
    margin-bottom: 10px;
	
}
.console-footer {
	
	text-align: left;
    color: white!important;
    font: 0.5rem Cutive Mono, sans-serif;
    margin-top: 10px;
    margin-bottom: 10px;
	
}

.consoleLinks {
	
	text-align: left;
    color: white!important;
    font: 0.6rem Cutive Mono, sans-serif;
    margin-top: 10px;
    margin-bottom: 10px;
	
}

}








.linkform {
	   display: contents;
}

@keyframes blink {
	0% {opacity: 0}
	25%{opacity: 0}
	50% {opacity: 1}
}

@keyframes blink1 {
	0% {opacity: 0}
	22%{opacity: 0}
	50% {opacity: 1}
}

@keyframes blink2 {
	0% {opacity: 0}
	33%{opacity: 0}
	50% {opacity: 1}
}

@keyframes blink3 {
	0% {opacity: 0}
	44%{opacity: 0}
	50% {opacity: 1}
}

blink{animation: blink 0.5s infinite;}

blink1{animation: blink1 1s infinite;}
blink2{animation: blink2 1s infinite;}
blink3{animation: blink3 1s infinite;}

